.activities-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
}

.activities-container > button {
  padding: 5px;
  margin: 10px;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

@media (max-width: 500px) {
  .activities-grid {
    grid-template-columns: repeat(3, auto) 0;
  }
  .activities-grid >  div:nth-child(4n) {
    visibility: hidden;
  }
}

.activities-grid > div {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.add-activity-form {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  justify-items: center;

  grid-template-columns: 1fr 1fr;
}

.add-activity-form > label, input {
  grid-column: 1 / span 2;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 1s, height 1s;
}

.modal-children {
  transition: visibility 0.5s;
}
