.top-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: lightcoral;
}

.top-bar > span {
  font-size: 30px;
  font-weight: bold;
}

.top-bar span {
  padding: 20px;
}

.top-bar > div {
  padding: 20px;
}
